.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  margin-left: 50px;
  margin-right: 50px;
  padding-top: 100px;
  padding-bottom: 20px;
  background-image: url("../../../../public/images/home/bg_footer.png");
  background-size: 100%;
  @media (max-width: 600px) {
    margin-top: 50px;
    padding-top: 20px;
  }
}

.footerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
}

.footerContainerTop {
  display: flex;
  flex-direction: row;
  width: 100%;
  @media (max-width: 600px) {
    flex-direction: column;
    padding-bottom: 20px;
  }
}

.footerContainerTopLeft {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
  text-align: left;
  padding-left: 20px;
  @media (max-width: 600px) {
    width: 100%;
    padding-left: 0px;
  }
}

.content {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: -0.03em;
  color: #8F9BB7;
  padding-bottom: 200px;
  @media (max-width: 600px) {
    padding-bottom: 100px;
  }
}

.footerContainerTopMidd {
  display: flex;
  flex-direction: row;
  justify-items: center;
  width: 50%;
  color: #FFFFFF;
  @media (max-width: 600px) {
    width: 100%;
  }
}

.footerContainerTopMiddLeftRight {
  display: flex;
  flex-direction: column;
  justify-items: center;
  width: 50%;
  gap: 20px;
  color: #8F9BB7;
  text-align: left;
  @media (max-width: 600px) {
    width: 100%;
  }
}

.footerContainerTopRight {
  display: flex;
  flex-direction: column;
  width: 33%;
  align-items: end;
  @media (max-width: 600px) {
    padding-top: 20px;
    width: 100%;
  }
}

.footerContainerTopRightForm {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 80%;
  background-color: #0E1330;
  border: 1px solid #282D45;
  border-radius: 5px;
  padding: 10px;
  gap: 10px;
  @media (max-width: 600px) {
    width: 100%;
  }

}

.footerContainerTopRightFormTitle {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: -0.03em;
  color: #F6F6F7;
}

.footerContainerTopRightFormInput {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}

.input {
  padding: 10px 27px;
  gap: 10px;
  width: 100%;
  height: 44px;
  background: #0E1330;
  border: 1px solid #282D45;
  border-radius: 50px;
}

.button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
  height: 46px;
  background: #7214FF;
  border-radius: 36px;
  border: none;
  color: #FFFFFF;
}


.footerContainerBottom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-top: 1px solid #282D45;
  width: 100%;
  padding: 20px;
  @media (max-width: 600px) {
    padding-left: 5px;
    text-align: left;
  }
}
