.blog {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
  padding-top: 70px;
}

.blogTop {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
  width: 70%;
}

.blogBottom {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 70%;
  gap: 20px;
  @media (max-width: 600px) {
    flex-direction: column;
  }
}

.blogBottomLeftMiddRight {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 33%;
  background-color: #0E1330;
  border: 1px solid #282D45;
  border-radius: 10px;
  gap: 10px;
  padding: 15px;
  @media (max-width: 600px) {
    flex-direction: column;
    width: 100%;
  }

}

.blogBottomLeftMiddRightImg {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}
.blogBottomLeftMiddRightContentFrontTitle{
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.03em;
  color: #F6F6F7;
}

.blogBottomLeftMiddRightContentFrontDes{
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #8F9BB7;
}

.blogBottomLeftMiddRightContent {
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.blogBottomLeftMiddRightFooter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 20px;
  border-top:1px solid #282D45;
}

.button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 136px;
  height: 46px;
  border-radius: 36px;
  border: none;
  margin: 5px;
  background-color: #282D45;
  color: #FFFFFF;
}

.button:hover {
  background: #7214FF;
}
