.useAndEarn {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 70px;
  gap: 40px;
}

.useAndEarnTop {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 70%;
  margin-bottom: 33px;
  font-style: normal;
  font-weight: 700;
  font-size: 56px;
  line-height: 64px;
  text-align: center;
  letter-spacing: -0.03em;
  background: linear-gradient(180deg, #F6F6F7 0%, #7E808F 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.useAndEarnBottom {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 70%;
  gap: 20px;
  @media (max-width: 600px) {
    flex-direction: column;
  }
}

.useAndEarnBottomLeftMiddRight {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 33%;
  background-color: #0E1330;
  text-align: left;
  padding-left: 30px;
  height: 323px;
  gap: 15px;
  @media (max-width: 600px) {
    width: 100%;
    padding-left: 10px;
  }
}

.useAndEarnBottomLeftMiddRightImg {
  height: 20%;
  padding-top: 10px;
  @media (max-width: 600px) {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}

.useAndEarnBottomLeftMiddRightTitle {
  display: flex;
  height: 20%;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.03em;
  color: #F6F6F7;
  padding-bottom: 12px;
}

.useAndEarnBottomLeftMiddRightDes {
  height: 60%;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #8F9BB7;
  padding-bottom: 30px;
}
