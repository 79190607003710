.banner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 400px;
  gap: 10px;
  margin-top: 70px;
  @media (max-width: 600px) {
    gap: 5px;
  }
}

.bannerTitle {
  font-style: normal;
  font-size: 64px;
  line-height: 72px;
  color: #FFFFFF;
  font-weight: 700;
  text-align: center;
  letter-spacing: -0.03em;
  background: linear-gradient(180deg, #F6F6F7 0%, #7E808F 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  @media (max-width: 600px) {
    line-height: 40px;
    font-size: 30px;
  }
}

.bannerDescription {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  text-align: center;
  margin: 1px;
  color: #8F9BB7;

}

.button {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px;
}

.bannerButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 136px;
  gap: 10px;
  height: 46px;
  background: #282D45;
  border-radius: 36px;
  border: none;
  margin: 5px;
  color: #FFFFFF;
}

.bannerButton:hover {
  background: #7214FF;
}
