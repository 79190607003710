.header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 94px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  background-color: #0E1330;
  @media (max-width: 600px) {
    height: 70px;
    justify-content: space-between;
    padding-top: 10px;
    padding-left: 20px;
    padding-right: 20px;
  }
}

.headerContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 90%;
  padding-top: 25px;
  padding-bottom: 25px;
}

.headerContainerLeft {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 33%;
}

.headerContainerMidd {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 50px;
  width: 33%;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #8F9BB7;
}

.headerContainerMiddTitle {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #8F9BB7;
}

.headerContainerRight {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 20px;
  width: 33%;
}


.navbar {
  overflow: hidden;
}

.navbar a {
  float: left;
  font-size: 16px;
  color: white;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

.dropdown {
}

.dropdown .dropbtn {
  font-size: 16px;
  border: none;
  outline: none;
  color: #8F9BB7;
  padding: 14px 16px;
  background-color: transparent;
  margin: 0;
}

.navbar a:hover, .dropdown:hover .dropbtn {
}

.dropdownContent {
  display: none;
  position: absolute;
  background-color: #0E1330;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdownContent a {
  float: none;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

.dropdownContent a:hover {
  background-color: #282D45;
}

.dropdown:hover .dropdownContent {
  display: block;
}


#submenu {
  position: absolute;
  top: 0;
  left: 100%;
  width: 100%;
  padding-top: 8px;
  margin-left: -1px;
  z-index: 1;
  display: none;
}

#mobileMenu > ul > li:hover #submenu {
  display: block;
}

.subMenuMobile{
  z-index: 1;
  margin-left: 30px;
}
