.feature {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content1 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 70%;
  margin-top: 40px;
  border: 1px solid #282D45;
  border-radius: 10px;
  background-size: 100%;
  @media (max-width: 600px) {
    padding-top: 10px;
    flex-direction: column;
    gap: 20px;
  }
}

.content1Left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 45%;
  height: 100%;
  gap: 30px;
  padding-left: 50px;
  text-align: left;
  @media (max-width: 600px) {
    padding: 20px;
    width: 100%;
  }
}

.content1LeftTitle {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.03em;
  color: #F6F6F7;
  @media (max-width: 600px) {
    padding-right: 10px;
    padding-left: 10px;
  }
}

.content1LeftDes {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #8F9BB7;
  padding-right: 10px;
  @media (max-width: 600px) {
    padding-right: 10px;
    padding-left: 10px;
  }
}

.content1Right {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 55%;
  color: #FFFFFF;
  @media (max-width: 600px) {
    width: 100%;
  }
}

.content1RightLeft {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 45%;
  padding: 25px;

}

.content1RightLeftImg {
  max-width: 215px;
  max-height: 345px;
}

.content1RightRight {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 55%;
  gap: 30px;
  @media (max-width: 600px) {
    width: 100%;
    padding-bottom: 10px;
  }
}

.content1RightRightIconAndText {
  display: flex;
  align-items: center;
  gap: 20px;
  text-align: left;
  color: #8F9BB7;
  @media (max-width: 600px) {
    gap: 10px;
  }
}

.content1RightRightIcon {
  max-height: 41px;
  max-width: 41px;
  @media (max-width: 600px) {
    width: 30px;
  }
}

.content1RightRightText {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  @media (max-width: 600px) {
    font-weight: 700;
    font-size: 10px;
  }
}


.button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 136px;
  height: 46px;
  border-radius: 36px;
  border: none;
  margin: 5px;
  background-color: #282D45;
  color: #FFFFFF;
}


.button:hover {
  background: #7214FF;
}


.content2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 70%;
  margin-top: 40px;
  gap: 38px;
  @media (max-width: 600px) {
    flex-direction: column;
  }
}

.content2Left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 40%;
  background-color: #0E1330;
  border: 1px solid #282D45;
  border-radius: 10px;
  gap: 10px;
  background-image: url("../../../../public/images/home/bg_feature3.png");
  @media (max-width: 600px) {
    flex-direction: column;
    width: 100%;
  }
}

.content2LeftTop {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 50px;
  //padding-left: 112px;
  //padding-right: 112px;
  @media (max-width: 600px) {
    padding: 10px;
    width: 100%;
  }
}

.content2LeftTopImg {
  max-width: 100%;
}

.content2LeftBottom {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 50%;
  gap: 20px;
  padding-left: 50px;
  text-align: left;
  padding-top: 20px;
  @media (max-width: 600px) {
    padding: 10px;
    width: 100%;
  }
}

.content2LeftBottomTitle {
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: -0.03em;
  color: #F6F6F7;
  @media (max-width: 600px) {
    padding-right: 10px;
    padding-left: 10px;
  }
}

.content2LeftBottomDes {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #8F9BB7;
  padding-right: 20px;
  @media (max-width: 600px) {
    padding-right: 10px;
    padding-left: 10px;
  }
}

.content2LeftBottomButton {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 10px;
}

.content2Right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 60%;
  background-image: url("../../../../public/images/home/bg_feature3.png");
  background-size: 100%;
  border: 1px solid #282D45;
  border-radius: 10px;
  gap: 10px;
  @media (max-width: 600px) {
    flex-direction: column;
    width: 100%;
  }
}

.content2RightTopTitle {
  padding-top: 50px;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: -0.03em;
  color: #F6F6F7;
  @media (max-width: 600px) {
    padding-right: 10px;
    padding-left: 10px;
  }
}

.content2RightTopDes {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #8F9BB7;
  padding-right: 20px;
  @media (max-width: 600px) {
    padding-right: 10px;
    padding-left: 10px;
  }
}

.content2RightTopButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.content2RightTop {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 50%;
  gap: 20px;
  padding-left: 50px;
  text-align: left;
  @media (max-width: 600px) {
    padding: 10px;
    width: 100%;
  }
}

.content2RightBottom {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.content2RightBottomImg {
  max-width: 100%;
  padding-left: 93px;
  padding-right: 93px;
  @media (max-width: 600px) {
    padding: 10px;
  }
}
