.comingsoon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 800px;
  background-color: #0E1330;
  font-style: normal;
  font-weight: 700;
  font-size: 64px;
  line-height: 72px;
  text-align: center;
  letter-spacing: -0.03em;
  background: linear-gradient(180deg, #F6F6F7 0%, #7E808F 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;

}
